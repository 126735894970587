import React, { useState, useEffect } from "react";
import axios from "axios";
import initiative, {
    addResponse,
    getAllInitiatives,
    getCurrentInitiative,
    setInitiativeAdded,
    shareInitiative,
} from "../redux/reducers/initiative";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import Initiative from "../interfaces/initiative";
import { Form, Field } from "react-final-form";
import { Modal, Tooltip, Overlay } from "react-bootstrap";
import { addUser, setAdded } from "../redux/reducers/user";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface CountryCode {
    name: string;
    dial_code: string;
    code: string;
}

const InitiativeForm: React.FC = () => {
    const { username } = useParams();
    const location = useLocation();
    const dispatch: Dispatch<any> = useDispatch();
    const navigate = useNavigate();
    const faqOpen = useSelector((state: any) => state.common.faqOpen);
    const currentInitiative: Initiative = useSelector(
        (state: any) => state.initiative.currentInitiative
    );
    const locations: number = useSelector(
        (state: any) => state.initiative.locations
    );
    const totalImpact = useSelector(
        (state: any) => state.initiative.totalImpact
    );
    const totalUsers = useSelector((state: any) => state.initiative.totalUsers);
    const userAdded: boolean = useSelector((state: any) => state.user.added);
    const initiativeAdded: boolean = useSelector(
        (state: any) => state.initiative.added
    );
    const impactText: string = useSelector(
        (state: any) => state.initiative.impactText
    );

    const currentImpact: number = useSelector(
        (state: any) => state.initiative.currentImpact
    );

    const [countryCodes, setCountryCodes] = useState<CountryCode[]>([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [successModalIsOpen, setIsSuccessModalIsOpen] = useState(false);
    const [initiativeFormValues, setInitiativeFormValues] = useState<any>({});
    const [initiativeForm, setInitiativeForm] = useState<any>();

    const [showTooltip, setShowTooltip] = useState(false);
    const [target, setTarget] = useState(null);

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [callingCode, setCallingCode] = useState(null);

    const initiatives: Initiative[] = useSelector(
        (state: any) => state.initiative.items
    );

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767); // Adjust the width as per your mobile breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const navigateToAction = () => {
        const section = document.getElementById("ongoing");
        if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    useEffect(() => {
        dispatch(getCurrentInitiative());
        dispatch(getAllInitiatives());
    }, []);

    useEffect(() => {
        if (userAdded) {
            setIsOpen(false);
            dispatch(setAdded(false));
            submitResponse();
        }
    }, [userAdded]);

    useEffect(() => {
        if (initiativeAdded) {
            setIsSuccessModalIsOpen(true);
            dispatch(setInitiativeAdded(false));
        }
    }, [initiativeAdded]);

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get("/assets/countrycodes.json");
                const uniqueCountryCodes = Array.from(
                    new Set(response.data.map((code: any) => code.dial_code))
                ).map((dial_code) => {
                    return response.data.find(
                        (code: any) => code.dial_code === dial_code
                    );
                });

                const sortedCountryCodes = uniqueCountryCodes.sort((a, b) => {
                    return a.dial_code.localeCompare(b.dial_code, undefined, {
                        numeric: true,
                    });
                });
                setCountryCodes(sortedCountryCodes);
                const locationResponse = await axios.get(
                    "http://ip-api.com/json"
                );
                const countryCode = locationResponse.data.countryCode;
                const matchedCountry = sortedCountryCodes.find(
                    (code: any) => code.code === countryCode
                );

                if (matchedCountry) {
                    console.log("Default Dial Code:", matchedCountry.dial_code);
                    setCallingCode(matchedCountry.dial_code);
                    // setDefaultCallingCode(matchedCountry.dial_code); // Set the default calling code
                }
            } catch (error) {
                console.error("Error fetching country codes", error);
            }
        };

        fetchCountryCodes();
    }, []);

    const validate = (values: any) => {
        const errors: any = {};
        currentInitiative?.questions?.forEach((question) => {
            const value = values[question?._id];
            if (!value && value !== 0) {
                errors[question?._id] = "Required";
            } else if (value == 0) {
                errors[question?._id] = "Value cannot be 0";
            } else if (value > 99) {
                errors[question?._id] = "Value must be 99 or less";
            }
        });
        return errors;
    };

    const userValidate = (values: any) => {
        const errors: any = {};

        // Required fields validation
        ["name", "ext", "phone", "email"].forEach((field) => {
            if (!values[field]) errors[field] = "Required";
        });

        // Additional validations
        if (values.phone && !/^\d{10,15}$/.test(values.phone)) {
            errors.phone = "Invalid phone number";
        }
        if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address";
        }

        return errors;
    };

    const submitResponse = (values?: any) => {
        const formData = new FormData();
        formData.append("username", username as string);
        formData.append(
            "answers",
            JSON.stringify(values || initiativeFormValues)
        );
        formData.append("initiative_id", currentInitiative?._id);
        dispatch(addResponse(formData));
    };

    const onSubmit = (values: any) => {
        if (localStorage.getItem("token")) {
            submitResponse(values);
        } else {
            setInitiativeFormValues(values);
            setIsOpen(true);
        }
    };

    const onUserSubmit = (values: any) => {
        values.username = username;
        dispatch(addUser(values));
    };

    const generateImpactShareMessage = (title: string, impact: number) => {
        const message = `Hey there!\n\nI just made a difference! 🌍 By taking a micro-action - ${title}, I saved *${impact} Kgs CO2e* and contributed to a more sustainable planet.\n\nI want your help! Spend just 10 minutes to take a micro-action and share it with 2 more humans.\n\nHere’s the link to take a micro-action: https://rethink.getre.org/rethink\n\nI didn’t realize it was this simple to be sustainable!`;

        // Encode the message to preserve line breaks and spaces
        return encodeURIComponent(message);
    };

    const generateImpactCopyMessage = (title: string, impact: number) => {
        const message = `Hey there!

I just made a difference! 🌍 By taking a micro-action - ${title}, I saved *${impact} Kgs CO2e* and contributed to a more sustainable planet.

I want your help! Spend just 10 minutes to take a micro-action and share it with 2 more humans.

Here’s the link to take a micro-action: https://rethink.getre.org/rethink

I didn’t realize it was this simple to be sustainable!`;

        return message;
    };

    const copyToClipboard = (e: any, message: string) => {
        navigator.clipboard.writeText(message).then(() => {
            setTarget(e.target); // Set the target for the tooltip
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 2000); // Hide tooltip after 2 seconds
        });
    };

    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header
                        closeButton
                        className="modal-bg"
                        style={{ border: "none" }}
                    >
                        <Modal.Title>Let's get to know you</Modal.Title>
                    </Modal.Header>
                    <div
                        className="modal-bg"
                        style={{
                            fontSize: "12px",
                            paddingLeft: "17px",
                            paddingRight: "17px",
                            marginTop: "-12px",
                        }}
                    >
                        We’ll share cool sustainability tips, your impact
                        reports, stories, the next micro-actions, and
                        incentives!
                    </div>
                    <Form
                        onSubmit={onUserSubmit}
                        validate={userValidate}
                        initialValues={{
                            ext: callingCode || "+91",
                        }}
                        render={({
                            handleSubmit,
                            submitting,
                            pristine,
                            form,
                        }) => (
                            <>
                                <Modal.Body
                                    className="modal-bg"
                                    style={{ border: "none" }}
                                >
                                    <form
                                        onSubmit={handleSubmit}
                                        autoComplete="new-password"
                                    >
                                        <Field
                                            component="input"
                                            name="name"
                                            type="text"
                                            className="form-control input-control"
                                            placeholder="First Name & Last Name"
                                            autoComplete="new-password" // Use non-standard value
                                        />
                                        <Field
                                            name="name"
                                            render={({ meta }) => (
                                                <div className="error">
                                                    {meta.error &&
                                                    meta.touched ? (
                                                        <span>
                                                            {meta.error}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                        <br />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Field
                                                    name="ext"
                                                    component="select"
                                                    className="form-control input-control input-control-arrow"
                                                >
                                                    {countryCodes.map(
                                                        (code: any) => (
                                                            <option
                                                                key={code.code}
                                                                value={
                                                                    code.dial_code
                                                                }
                                                            >
                                                                {code.dial_code}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                                <Field
                                                    name="ext"
                                                    render={({ meta }) => (
                                                        <div className="error">
                                                            {meta.error &&
                                                            meta.touched ? (
                                                                <span>
                                                                    {meta.error}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="col-md-8">
                                                <Field
                                                    component="input"
                                                    name="phone"
                                                    type="number"
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === "e") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    className="form-control input-control"
                                                    placeholder="Mobile Number"
                                                    autoComplete="new-password" // Use non-standard value
                                                />
                                                <div className="whatsapp-info">
                                                    <div className="first-line">
                                                        <span className="italic-text">
                                                            <i>Make sure</i>
                                                        </span>
                                                        <img
                                                            src="assets/images/icons/whatsapp.svg"
                                                            alt="WhatsApp Logo"
                                                            className="whatsapp-logo-inline"
                                                        />
                                                        <span className="italic-text">
                                                            <i>
                                                                WhatsApp is
                                                                enabled on this
                                                                number to
                                                            </i>
                                                        </span>
                                                    </div>
                                                    <div className="second-line">
                                                        <span className="italic-text">
                                                            <i>
                                                                receive Impact
                                                                Reports
                                                            </i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <Field
                                                    name="phone"
                                                    render={({ meta }) => (
                                                        <div className="error">
                                                            {meta.error &&
                                                            meta.touched ? (
                                                                <span>
                                                                    {meta.error}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <Field
                                            component="input"
                                            name="email"
                                            type="text"
                                            className="form-control input-control"
                                            placeholder="Email"
                                            autoComplete="new-password" // Use non-standard value
                                        />
                                        <Field
                                            name="email"
                                            render={({ meta }) => (
                                                <div className="error">
                                                    {meta.error &&
                                                    meta.touched ? (
                                                        <span>
                                                            {meta.error}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </form>
                                </Modal.Body>
                                <Modal.Footer
                                    style={{
                                        border: "none",
                                    }}
                                    className="modal-bg"
                                >
                                    <div
                                        style={{
                                            fontSize: "12px",
                                            width: "90%",
                                            textAlign: "center",
                                        }}
                                    >
                                        By submitting your response, you agree
                                        to our{" "}
                                        <a href="terms.pdf" target="_blank">
                                            Terms & Conditions
                                        </a>
                                    </div>
                                    <br />

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flex: 1,
                                            fontSize: "13px",
                                        }}
                                    >
                                        <div>
                                            <span>
                                                <b>
                                                    Please verify your details{" "}
                                                </b>{" "}
                                                <br /> Name:{" "}
                                                {form.getState().values.name}
                                            </span>
                                            <br />
                                            <div
                                                style={{
                                                    display: "flex",
                                                }}
                                            >
                                                <span>Phone No: </span>
                                                <span>
                                                    <img
                                                        style={{
                                                            marginTop: "3px",
                                                        }}
                                                        src="assets/images/icons/whatsapp.svg"
                                                        alt="WhatsApp Logo"
                                                        className="whatsapp-logo-inline"
                                                    />
                                                </span>
                                                {form.getState().values.ext} -{" "}
                                                {form.getState().values.phone}
                                            </div>
                                            Email:{" "}
                                            {form.getState().values.email}
                                        </div>
                                        <div className="buttons">
                                            <a
                                                style={{
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginRight: "20px",
                                                }}
                                                href=""
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    form.submit();
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "35px",
                                                    }}
                                                    src="assets/images/icons/submit.svg"
                                                    alt=""
                                                />
                                                <span
                                                    style={{
                                                        fontSize: 15,
                                                        color: "#3E3E3E",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Submit
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </>
                        )}
                    />
                </Modal>
            </div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={successModalIsOpen}
                    onHide={() => {
                        setIsSuccessModalIsOpen(false);
                        window.location.reload();
                    }}
                >
                    <Modal.Header
                        closeButton
                        className="modal-bg"
                        style={{ border: "none", justifyContent: "center" }}
                    >
                        <Modal.Title>You have made an impact!</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-bg" style={{ border: "none" }}>
                        <div className="modal-body-container">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: impactText.replace(/\n/g, "<br/>"),
                                }}
                            ></div>
                            <div className="share-invite">
                                <b>
                                    <i>Share your impact &nbsp;</i>
                                </b>
                                <img
                                    onClick={() => {
                                        dispatch(
                                            shareInitiative({
                                                type: "whatsapp",
                                                initiative_id:
                                                    currentInitiative._id,
                                            })
                                        );
                                        const encodedMessage =
                                            generateImpactShareMessage(
                                                currentInitiative.title,
                                                currentImpact
                                            );

                                        const whatsappURL = `https://api.whatsapp.com/send?text=${encodedMessage}`;
                                        window.open(whatsappURL, "_blank");
                                    }}
                                    style={{
                                        width: "25px",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                    }}
                                    src="/assets/images/icons/whatsapp-share.svg"
                                    alt="WhatsApp Logo"
                                    className="whatsapp-logo-inline"
                                />
                                <img
                                    onClick={(e: any) => {
                                        dispatch(
                                            shareInitiative({
                                                type: "instagram",
                                                initiative_id:
                                                    currentInitiative._id,
                                            })
                                        );
                                        const message =
                                            generateImpactCopyMessage(
                                                currentInitiative.title,
                                                currentImpact
                                            );
                                        copyToClipboard(e, message);
                                        const instagramDMUrl =
                                            "https://www.instagram.com/direct/inbox/";
                                        window.open(instagramDMUrl, "_blank");
                                    }}
                                    style={{
                                        width: "25px",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                    }}
                                    src="/assets/images/icons/instagram-share.svg"
                                    alt="Link logo"
                                    className="whatsapp-logo-inline"
                                />
                                <img
                                    onClick={(e: any) => {
                                        const isMobile =
                                            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                                                navigator.userAgent
                                            );
                                        const message =
                                            generateImpactCopyMessage(
                                                currentInitiative.title,
                                                currentImpact
                                            );

                                        if (isMobile) {
                                            // Open the sharing intent on mobile devices
                                            const shareData = {
                                                title: currentInitiative.title,
                                                text: message,
                                                url: window.location.href,
                                            };

                                            if (navigator.share) {
                                                navigator
                                                    .share(shareData)
                                                    .catch((err) =>
                                                        console.log(
                                                            "Error sharing",
                                                            err
                                                        )
                                                    );
                                            } else {
                                                console.log(
                                                    "Sharing not supported on this browser"
                                                );
                                            }
                                        } else {
                                            // Copy the link to clipboard on desktop
                                            dispatch(
                                                shareInitiative({
                                                    type: "copylink",
                                                    initiative_id:
                                                        currentInitiative._id,
                                                })
                                            );
                                            copyToClipboard(e, message);
                                        }
                                    }}
                                    style={{
                                        width: "25px",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                    }}
                                    src="/assets/images/icons/copy-link-share.svg"
                                    alt="Link logo"
                                    className="whatsapp-logo-inline"
                                />
                                <Overlay
                                    target={target}
                                    show={showTooltip}
                                    placement="top"
                                >
                                    {(props) => (
                                        <Tooltip
                                            id="copy-link-tooltip"
                                            {...props}
                                        >
                                            Link copied!
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </div>
                            <div>
                                <b style={{ fontStyle: "italic" }}>
                                    <i>
                                        You will receive your impact report on
                                        the WhatsApp number you submitted within
                                        24-72 hours from <br />
                                        +91 89285 45695. Please save this number
                                        as ReThink.
                                    </i>
                                </b>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer
                        className="modal-bg"
                        style={{ border: "none" }}
                    ></Modal.Footer>
                </Modal>
            </div>
            <section className="banner">
                <div className="container">
                    <div className="bannerWrapper">
                        <div className="bgongoing" id="ongoing">
                            <div className="ongoingTask">
                                <div className="wrapper">
                                    <h1
                                        style={{
                                            fontSize: "21px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Take Micro-action
                                    </h1>
                                    <div className="box">
                                        <div className="row">
                                            <div className="col-2">
                                                <img
                                                    style={{
                                                        maxWidth: isMobile
                                                            ? "unset"
                                                            : "100%",
                                                        width: isMobile
                                                            ? "55px"
                                                            : "",
                                                        marginLeft: isMobile
                                                            ? "unset"
                                                            : 10,
                                                    }}
                                                    src="assets/images/icons/task.svg"
                                                    className="icon"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-8">
                                                <h2>
                                                    {currentInitiative?.title}
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            className="description"
                                            dangerouslySetInnerHTML={{
                                                __html: currentInitiative?.description?.replace(
                                                    /\n/g,
                                                    "<br/>"
                                                ),
                                            }}
                                        ></div>
                                        <Form
                                            onSubmit={onSubmit}
                                            validate={validate}
                                            render={({
                                                handleSubmit,
                                                submitting,
                                                pristine,
                                                form,
                                            }) => (
                                                <form onSubmit={handleSubmit}>
                                                    {currentInitiative?.questions?.map(
                                                        (question) => {
                                                            if (
                                                                question?.type ===
                                                                "number"
                                                            ) {
                                                                return (
                                                                    <React.Fragment
                                                                        key={
                                                                            question._id
                                                                        }
                                                                    >
                                                                        <Field
                                                                            component="input"
                                                                            name={
                                                                                question?._id
                                                                            }
                                                                            placeholder={
                                                                                question?.placeholder
                                                                            }
                                                                            type="number"
                                                                            min="0"
                                                                            max="99"
                                                                            onFocus={(
                                                                                e: any
                                                                            ) => {
                                                                                e.target.placeholder =
                                                                                    "";
                                                                            }}
                                                                            onBlur={(
                                                                                e: any
                                                                            ) => {
                                                                                e.target.placeholder =
                                                                                    question?.placeholder;
                                                                            }}
                                                                            onKeyDown={(
                                                                                e: any
                                                                            ) => {
                                                                                if (
                                                                                    e.key ===
                                                                                        "-" ||
                                                                                    e.key ===
                                                                                        "+" ||
                                                                                    e.key ===
                                                                                        "e"
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                        />
                                                                        <Field
                                                                            name={
                                                                                question?._id
                                                                            }
                                                                            render={({
                                                                                meta,
                                                                            }) => (
                                                                                <div className="error">
                                                                                    {meta.error &&
                                                                                    meta.touched ? (
                                                                                        <span>
                                                                                            {
                                                                                                meta.error
                                                                                            }
                                                                                        </span>
                                                                                    ) : null}
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        }
                                                    )}

                                                    <div
                                                        className="buttons"
                                                        style={{
                                                            justifyContent:
                                                                "center",
                                                        }}
                                                    >
                                                        <a
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setInitiativeForm(
                                                                    form
                                                                );
                                                                form.submit();
                                                            }}
                                                        >
                                                            <img
                                                                src="assets/images/icons/submit.svg"
                                                                alt=""
                                                            />
                                                            <span>Submit</span>
                                                        </a>
                                                    </div>
                                                </form>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bgimpact" id="impact">
                            <div className="impact">
                                <div
                                    className="wrapper"
                                    style={{ marginBottom: "20px" }}
                                >
                                    <h1
                                        style={{
                                            fontSize: "21px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Impact
                                    </h1>
                                    <ul className="box">
                                        <li className="">
                                            <p>
                                                <span> Impacters from</span>
                                                <br />
                                                {/* {currentInitiative?.title} */}
                                            </p>
                                            <span
                                                className="num"
                                                style={{ bottom: "33px" }}
                                            >
                                                {/* {
                                                    currentInitiative?.totalEntityCount
                                                } */}
                                                {locations}{" "}
                                                <span
                                                    style={{
                                                        color: "white",
                                                        fontSize: "19px",
                                                    }}
                                                >
                                                    locations
                                                </span>
                                            </span>
                                        </li>
                                        <li className="">
                                            <p>
                                                <span
                                                    style={{ fontSize: "17px" }}
                                                >
                                                    {" "}
                                                    Impact (ongoing
                                                    micro-action)
                                                </span>
                                            </p>
                                            <div
                                                className="num"
                                                style={{ fontSize: "20px" }}
                                            >
                                                {/* {
                                                    currentInitiative?.totalEntityCount
                                                } */}
                                                {currentInitiative?.duration ===
                                                    "month" && (
                                                    <>
                                                        {parseInt(
                                                            (
                                                                (currentInitiative?.totalImpact *
                                                                    12) /
                                                                1000
                                                            ).toString()
                                                        )}{" "}
                                                    </>
                                                )}
                                                {currentInitiative?.duration ===
                                                    "year" && (
                                                    <>
                                                        {parseInt(
                                                            (
                                                                currentInitiative?.totalImpact /
                                                                1000
                                                            ).toString()
                                                        )}{" "}
                                                    </>
                                                )}
                                                Kgs CO2e*
                                                <br />
                                                <div
                                                    style={{
                                                        fontSize: "12px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    (saved/year)
                                                </div>
                                            </div>
                                        </li>
                                        <li className="">
                                            <p>
                                                <span
                                                    style={{ color: "black" }}
                                                >
                                                    {" "}
                                                    Sustainabilty Warriors
                                                </span>
                                            </p>
                                            <div
                                                className="num"
                                                style={{
                                                    fontSize: "20px",
                                                    color: "black",
                                                    bottom: "32px",
                                                }}
                                            >
                                                {/* {
                                                    currentInitiative?.totalEntityCount
                                                } */}
                                                {totalUsers}
                                            </div>
                                        </li>
                                        <li className="">
                                            <p>
                                                <span>
                                                    {" "}
                                                    Total impact of ReThink
                                                </span>
                                            </p>
                                            <div
                                                className="num"
                                                style={{
                                                    fontSize: "20px",
                                                }}
                                            >
                                                {parseInt(
                                                    (
                                                        totalImpact / 1000
                                                    ).toString()
                                                )}{" "}
                                                Kgs CO2e*
                                                <br />
                                                <div
                                                    style={{
                                                        fontSize: "12px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    (saved/year)
                                                </div>
                                            </div>
                                        </li>
                                        {/* <li className="">
                                            <h3>
                                                Total impact of ReThink
                                                <br />
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {totalImpact / 1000} Kgs
                                                    CO2e
                                                </span>
                                            </h3>
                                        </li> */}
                                    </ul>
                                </div>
                                <div
                                    style={{
                                        color: "white",
                                        fontSize: "12px",
                                        textAlign: "center",
                                    }}
                                >
                                    Note: CO2e is a standard unit that measures
                                    the impact of greenhouse gases in terms of
                                    the equivalent amount of carbon dioxide
                                    (CO₂) contributing to global warming.
                                </div>
                            </div>
                        </div>
                        <div className="bgconcluded" id="upcoming_concluded">
                            <div className="concludedTask">
                                <div className="wrapper">
                                    <h1
                                        style={{
                                            fontSize: "21px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Explore Micro-actions
                                    </h1>
                                    <ul className="box">
                                        {initiatives.map(
                                            (initiative: Initiative) => {
                                                return (
                                                    <li>
                                                        <a
                                                            onClick={(
                                                                e: any
                                                            ) => {
                                                                e.preventDefault();
                                                                navigate(
                                                                    `/${username}/micro-actions`
                                                                );
                                                                // navigate(
                                                                //     username +
                                                                //         "/micro-actions"
                                                                // );
                                                                // window.location.href =
                                                                //     username +
                                                                //     "/micro-actions";
                                                            }}
                                                        >
                                                            {
                                                                initiative.shortTitle
                                                            }
                                                        </a>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="d-flex flex-column">
                <section
                    className="vnm | padding-block-500 order-md-1 order-1"
                    id="story"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>The story</h1>
                                <br />
                                {/* <p className="mt-5"> */}
                                <p>
                                    <b>
                                        In our journey of sustainable living, we
                                        met two types of people...
                                    </b>{" "}
                                    those who wanted to be sustainable but
                                    didn’t know how to start, and those who were
                                    hesitant, and worried about the time,
                                    energy, and cost involved.
                                </p>
                                <p>
                                    ReThink was designed with both of these
                                    groups in mind. It’s a program that focuses
                                    on micro-actions to create a positive impact
                                    on the environment. These micro-actions are
                                    small, manageable tasks that take just 15
                                    minutes each month, but when combined, they
                                    build a powerful force for good and
                                    demonstrate that you don’t need to overhaul
                                    your entire routine to make a difference.
                                </p>
                                <p>
                                    By joining ReThink, you become part of what
                                    could be the largest movement of our time.
                                    Together, we can reshape our world and leave
                                    a legacy that future generations will
                                    remember and celebrate, one micro-action at
                                    a time.
                                </p>
                                {/* </p> */}
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <button
                                style={{
                                    color: "#000000",
                                    backgroundColor: "orange",
                                    border: "none",
                                    fontSize: "16px",
                                    width: isMobile ? "100%" : "auto",
                                }}
                                className="btn btn-primary"
                                onClick={navigateToAction}
                            >
                                Take a Micro-action
                            </button>
                        </div>
                    </div>
                </section>
                <section className="faq | order-md-2 order-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-2">
                                <h1>FAQ's</h1>
                            </div>
                            <div className="col-md-8 col-10">
                                <div
                                    className="accordion"
                                    id="accordionExample"
                                >
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                            >
                                                What is ReThink?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    ReThink is a program
                                                    designed to encourage
                                                    positive environmental
                                                    impact through simple,
                                                    manageable micro-actions.
                                                    Each month, participants are
                                                    introduced to a new
                                                    micro-action that can be
                                                    completed in just 15
                                                    minutes, demonstrating that
                                                    small steps can lead to
                                                    significant change.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo"
                                            >
                                                How does ReThink work?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTwo"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    We share the micro-action of
                                                    the month. One can act on it
                                                    and upload the actions on
                                                    our platform. Within 24-48
                                                    hours, you will receive the
                                                    impact information on the
                                                    WhatsApp number provided by
                                                    you while uploading the
                                                    actions. When many people
                                                    take such micro-actions
                                                    together, their collective
                                                    impact can be substantial.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree"
                                                aria-expanded="false"
                                                aria-controls="collapseThree"
                                            >
                                                Who can join ReThink?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseThree"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Anyone can join ReThink!
                                                    Whether you’re new to
                                                    sustainability or looking to
                                                    enhance your existing
                                                    efforts, whether you are an
                                                    individual or an entity, our
                                                    program is designed to be
                                                    accessible to everyone.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFour"
                                                aria-expanded="false"
                                                aria-controls="collapseFour"
                                            >
                                                How much time will I need to
                                                commit every month?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseFour"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Each micro-action takes just
                                                    15 minutes or less to
                                                    complete. The program is
                                                    designed to fit easily into
                                                    your daily life without
                                                    requiring a significant time
                                                    commitment or additional
                                                    layers. We are requesting
                                                    you to invest 15 minutes
                                                    every month for our common
                                                    home, planet Earth.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseFive"
                                                aria-expanded="false"
                                                aria-controls="collapseFive"
                                            >
                                                Why should I join this movement?
                                                What's my benefit?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseFive"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    To earn Karma points. Karma
                                                    points fetch you incentives,
                                                    discounts, and free offers.
                                                    When you take micro-actions
                                                    and record them on ReThink,
                                                    each micro-action and the
                                                    corresponding impact will
                                                    fetch you Karma points. More
                                                    the points, the larger are
                                                    the incentives. This benefit
                                                    of course is in addition to
                                                    starting your sustainable
                                                    journey and nurturing the
                                                    planet.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseSix"
                                                aria-expanded="false"
                                                aria-controls="collapseSix"
                                            >
                                                How do I receive the monthly
                                                micro-actions?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseSix"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    You will receive updates
                                                    about the monthly
                                                    micro-actions via WhatsApp
                                                    (do ensure your number and
                                                    email provided are
                                                    accurate). Or, you can check
                                                    it on our platform directly.
                                                    Make sure to follow us on
                                                    social media to stay
                                                    informed, we will be posting
                                                    the micro-actions on
                                                    Instagram and Twitter too.
                                                    You can follow us from the
                                                    social media icons shared in
                                                    the footer at the bottom of
                                                    this page.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="collapseSeven"
                                            >
                                                Is there a cost to participate?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseSeven"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Participation in ReThink is
                                                    free for individuals. We
                                                    believe that everyone should
                                                    have the opportunity to
                                                    contribute to sustainability
                                                    without financial barriers.
                                                    For entities (e.g.,
                                                    corporate companies,
                                                    educational institutions,
                                                    non-profits, etc.) who wish
                                                    to run ReThink as a program
                                                    in their ecosystem, we will
                                                    charge INR 20 (for
                                                    India-based entities) and
                                                    $0.5 (for entities outside
                                                    India) per month per user
                                                    for the following two
                                                    reasons: to consolidate
                                                    their organization's data
                                                    and provide them with a
                                                    dashboard of their positive
                                                    impact, and to compensate
                                                    for the time and effort it
                                                    takes to create the
                                                    dashboard, so they are
                                                    taking the program
                                                    seriously.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseEight"
                                                aria-expanded="false"
                                                aria-controls="collapseEight"
                                            >
                                                Can I suggest new micro-actions?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseEight"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Absolutely! We welcome
                                                    suggestions from
                                                    participants. If you have
                                                    ideas for micro-actions that
                                                    align with our goals, please
                                                    share them with us through
                                                    our website or social media
                                                    channels.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseNine"
                                                aria-expanded="false"
                                                aria-controls="collapseNine"
                                            >
                                                How can I get involved further?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseNine"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    Beyond participating in the
                                                    monthly micro-actions, you
                                                    can help spread the word
                                                    about ReThink, engage more
                                                    people in the movement, and
                                                    contribute to ongoing
                                                    discussions about
                                                    sustainability. Your
                                                    involvement helps amplify
                                                    our collective impact.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTen"
                                                aria-expanded="false"
                                                aria-controls="collapseTen"
                                            >
                                                Is ReThink the only program you
                                                are running?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTen"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>
                                                    No, we are working on
                                                    building an eco-verse, what
                                                    we like to call a Re-verse,
                                                    to rewrite the equation we
                                                    have with our planet. We
                                                    will be sharing more
                                                    initiatives in the near
                                                    future.
                                                </p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "orange",
                                                            border: "none",
                                                            fontSize: "16px",
                                                            color: "#000000",
                                                            width: isMobile
                                                                ? "100%"
                                                                : "auto",
                                                        }}
                                                        className="btn btn-primary"
                                                        onClick={
                                                            navigateToAction
                                                        }
                                                    >
                                                        Take a Micro-action
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <br />
        </>
    );
};

export default InitiativeForm;
