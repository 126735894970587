import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';

interface UserResponse {
    message:string,
    token:string
}

interface UserState{
    token:string | null,
    added: boolean | null
}

interface UserParams{
    name: string,
    email:string,
    ext:string,
    phone:number
}

export const addUser = createAsyncThunk('/addUser', async (values:UserParams, { rejectWithValue }) => {
  try {
    const response = await api.post('/user', values);
    return response.data as UserResponse;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addSuggestion = createAsyncThunk('/addSuggestion', async (values:any, { rejectWithValue }) => {
  try {
    const response = await api.post('/user/suggestion', values);
    return response.data as any;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    token:null
  } as UserState,
  reducers: {
    setToken:(state,action) =>{
        state.token = action.payload;
    },
    setAdded:(state,action) =>{
        state.added = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUser.fulfilled, (state,action) => {
        state.token = action.payload.token;
        state.added = true;
        localStorage.setItem('token', action.payload.token);
      })
  },
});

export const { setToken, setAdded } = userSlice.actions;
export default userSlice.reducer;