import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppRouter from "./navigation/router";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App: React.FC = () => (
    <Provider store={store}>
        <div>
            <Header />
            <AppRouter />
            <Footer />
        </div>
    </Provider>
);

export default App;
