import { Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Tooltip, Overlay } from "react-bootstrap";
import Initiative from "../interfaces/initiative";
import { addSuggestion, addUser, setAdded } from "../redux/reducers/user";
import {
    addResponse,
    getAllFilters,
    getAllInitiatives,
    setInitiativeAdded,
    shareInitiative,
} from "../redux/reducers/initiative";
import { Form, Field } from "react-final-form";
import { useParams } from "react-router-dom";
import Filters from "../interfaces/filters";
import { MultiSelect, Option } from "react-multi-select-component";

interface CountryCode {
    name: string;
    dial_code: string;
    code: string;
}

const MicroActions: React.FC = () => {
    const { username } = useParams();
    const dispatch: Dispatch<any> = useDispatch();
    const initiatives: Initiative[] = useSelector(
        (state: any) => state.initiative.items
    );
    const [countryCodes, setCountryCodes] = useState<CountryCode[]>([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [initiative, setInitiative] = useState({} as Initiative);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [initiativeFormValues, setInitiativeFormValues] = useState<any>({});
    const [callingCode, setCallingCode] = useState(null);
    const [successModalIsOpen, setIsSuccessModalIsOpen] = useState(false);
    const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
    const [suggestModalOpen, setIsSuggestModalOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [target, setTarget] = useState(null);
    const impactText: string = useSelector(
        (state: any) => state.initiative.impactText
    );

    const userAdded: boolean = useSelector((state: any) => state.user.added);
    const initiativeAdded: boolean = useSelector(
        (state: any) => state.initiative.added
    );

    const currentImpact: number = useSelector(
        (state: any) => state.initiative.currentImpact
    );

    const filters: Filters[] = useSelector(
        (state: any) => state.initiative.filters
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767); // Adjust the width as per your mobile breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(getAllInitiatives());
        dispatch(getAllFilters());
    }, []);

    const onSubmit = (values: any) => {
        if (localStorage.getItem("token")) {
            submitResponse(values);
        } else {
            setInitiativeFormValues(values);
            setIsOpen(true);
        }
    };

    const toggleFilter = (filterLabel: string) => {
        setActiveFilters((prevFilters) => {
            if (prevFilters.includes(filterLabel)) {
                return prevFilters.filter((f) => f !== filterLabel); // Remove filter
            } else {
                return [...prevFilters, filterLabel]; // Add filter
            }
        });
    };

    const userValidate = (values: any) => {
        const errors: any = {};

        // Required fields validation
        ["name", "ext", "phone", "email"].forEach((field) => {
            if (!values[field]) errors[field] = "Required";
        });

        // Additional validations
        if (values.phone && !/^\d{10,15}$/.test(values.phone)) {
            errors.phone = "Invalid phone number";
        }
        if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address";
        }

        return errors;
    };

    const submitResponse = (values?: any) => {
        const formData = new FormData();
        formData.append("username", username as string);
        formData.append(
            "answers",
            JSON.stringify(values || initiativeFormValues)
        );
        formData.append("initiative_id", initiative?._id);
        dispatch(addResponse(formData));
    };

    const validate = (values: any) => {
        const errors: any = {};
        initiative?.questions?.forEach((question) => {
            const value = values[question?._id];
            if (!value && value !== 0) {
                errors[question?._id] = "Required";
            } else if (value == 0) {
                errors[question?._id] = "Value cannot be 0";
            } else if (value > 99) {
                errors[question?._id] = "Value must be 99 or less";
            }
        });
        return errors;
    };

    const onUserSubmit = (values: any) => {
        values.username = username;
        dispatch(addUser(values));
    };

    useEffect(() => {
        if (userAdded) {
            setIsOpen(false);
            dispatch(setAdded(false));
            submitResponse();
        }
    }, [userAdded]);

    useEffect(() => {
        if (initiativeAdded) {
            setIsSuccessModalIsOpen(true);
            dispatch(setInitiativeAdded(false));
        }
    }, [initiativeAdded]);

    const generateShareMessage = (microActionLink: string) => {
        const message = `Hey there!\n\nI have an interesting challenge for you.\n\nLet me know how much time it takes you to complete this micro-action: https://rethink.getre.org/rethink.\n\nGuess it’s super easy to be sustainable!`;

        // Encode the message to preserve line breaks and spaces
        return encodeURIComponent(message);
    };

    const generateImpactShareMessage = (title: string, impact: number) => {
        const message = `Hey there!\n\nI just made a difference! 🌍 By taking a micro-action - ${title}, I saved *${impact} Kgs CO2e* and contributed to a more sustainable planet.\n\nI want your help! Spend just 10 minutes to take a micro-action and share it with 2 more humans.\n\nHere’s the link to take a micro-action: https://rethink.getre.org/rethink\n\nI didn’t realize it was this simple to be sustainable!`;

        // Encode the message to preserve line breaks and spaces
        return encodeURIComponent(message);
    };

    const generateCopyMessage = (microActionLink: string) => {
        const message = `Hey there!
        
I have an interesting challenge for you.
        
Let me know how much time it takes you to complete this micro-action: https://rethink.getre.org/rethink.
        
Guess it’s super easy to be sustainable!`;

        // Encode the message to preserve line breaks and spaces
        return message;
    };

    const generateImpactCopyMessage = (title: string, impact: number) => {
        const message = `Hey there!

I just made a difference! 🌍 By taking a micro-action - ${title}, I saved *${impact} Kgs CO2e* and contributed to a more sustainable planet.

I want your help! Spend just 10 minutes to take a micro-action and share it with 2 more humans.

Here’s the link to take a micro-action: https://rethink.getre.org/rethink

I didn’t realize it was this simple to be sustainable!`;

        return message;
    };

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get("/assets/countrycodes.json");
                const uniqueCountryCodes = Array.from(
                    new Set(response.data.map((code: any) => code.dial_code))
                ).map((dial_code) => {
                    return response.data.find(
                        (code: any) => code.dial_code === dial_code
                    );
                });

                const sortedCountryCodes = uniqueCountryCodes.sort((a, b) => {
                    return a.dial_code.localeCompare(b.dial_code, undefined, {
                        numeric: true,
                    });
                });
                setCountryCodes(sortedCountryCodes);
                const locationResponse = await axios.get(
                    "http://ip-api.com/json"
                );
                const countryCode = locationResponse.data.countryCode;
                const matchedCountry = sortedCountryCodes.find(
                    (code: any) => code.code === countryCode
                );

                if (matchedCountry) {
                    console.log("Default Dial Code:", matchedCountry.dial_code);
                    setCallingCode(matchedCountry.dial_code);
                    // setDefaultCallingCode(matchedCountry.dial_code); // Set the default calling code
                }
            } catch (error) {
                console.error("Error fetching country codes", error);
            }
        };

        fetchCountryCodes();
    }, []);

    const filterOptions = filters.map((filter: any) => ({
        value: filter.type,
        label: filter.label,
        color: filter.color,
        textColor: filter.textColor,
    }));

    const handleFilterChange = (selectedOptions: any) => {
        const selectedLabels = selectedOptions
            ? selectedOptions.map((option: any) => option.value)
            : [];
        setActiveFilters(selectedLabels);
    };

    const filteredInitiatives = [...initiatives].sort(
        (a: Initiative, b: Initiative) => {
            const matchesFilters = (initiative: Initiative) =>
                activeFilters.some((filter) =>
                    initiative.specificFilter.some(
                        (sf) => sf.type.toLowerCase() === filter.toLowerCase()
                    )
                );

            if (matchesFilters(a) && !matchesFilters(b)) {
                return -1;
            }

            if (!matchesFilters(a) && matchesFilters(b)) {
                return 1;
            }

            return 0;
        }
    );

    const copyToClipboard = (e: any, message: string) => {
        navigator.clipboard.writeText(message).then(() => {
            setTarget(e.target); // Set the target for the tooltip
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 2000); // Hide tooltip after 2 seconds
        });
    };

    const onSuggestionSubmit = (values: any) => {
        dispatch(addSuggestion({ ...values, username }));
        setIsSuggestModalOpen(false);
        setThankYouModalOpen(true);
    };

    const suggestionValidate = (values: any) => {
        const errors: any = {};
        if (!localStorage.getItem("token")) {
            ["name", "ext", "phone", "email", "suggestion"].forEach((field) => {
                if (!values[field]) errors[field] = "Required";
            });

            if (values.phone && !/^\d{10,15}$/.test(values.phone)) {
                errors.phone = "Invalid phone number";
            }
            if (
                values.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = "Invalid email address";
            }
        } else {
            ["suggestion"].forEach((field) => {
                if (!values[field]) errors[field] = "Required";
            });
        }
        return errors;
    };

    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header
                        closeButton
                        className="modal-bg"
                        style={{ border: "none" }}
                    >
                        <Modal.Title>Let's get to know you</Modal.Title>
                    </Modal.Header>
                    <div
                        className="modal-bg"
                        style={{
                            fontSize: "12px",
                            paddingLeft: "17px",
                            paddingRight: "17px",
                            marginTop: "-12px",
                        }}
                    >
                        We’ll share cool sustainability tips, your impact
                        reports, stories, the next micro-actions, and
                        incentives!
                    </div>
                    <Form
                        onSubmit={onUserSubmit}
                        validate={userValidate}
                        initialValues={{
                            ext: callingCode || "+91",
                        }}
                        render={({
                            handleSubmit,
                            submitting,
                            pristine,
                            form,
                        }) => (
                            <>
                                <Modal.Body
                                    className="modal-bg"
                                    style={{ border: "none" }}
                                >
                                    <form
                                        onSubmit={handleSubmit}
                                        autoComplete="new-password"
                                    >
                                        <Field
                                            component="input"
                                            name="name"
                                            type="text"
                                            className="form-control input-control"
                                            placeholder="First Name & Last Name"
                                            autoComplete="new-password" // Use non-standard value
                                        />
                                        <Field
                                            name="name"
                                            render={({ meta }) => (
                                                <div className="error">
                                                    {meta.error &&
                                                    meta.touched ? (
                                                        <span>
                                                            {meta.error}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                        <br />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Field
                                                    name="ext"
                                                    component="select"
                                                    className="form-control input-control input-control-arrow"
                                                >
                                                    {countryCodes.map(
                                                        (code: any) => (
                                                            <option
                                                                key={code.code}
                                                                value={
                                                                    code.dial_code
                                                                }
                                                            >
                                                                {code.dial_code}
                                                            </option>
                                                        )
                                                    )}
                                                </Field>
                                                <Field
                                                    name="ext"
                                                    render={({ meta }) => (
                                                        <div className="error">
                                                            {meta.error &&
                                                            meta.touched ? (
                                                                <span>
                                                                    {meta.error}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="col-md-8">
                                                <Field
                                                    component="input"
                                                    name="phone"
                                                    type="number"
                                                    onKeyDown={(e: any) => {
                                                        if (e.key === "e") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    className="form-control input-control"
                                                    placeholder="Mobile Number"
                                                    autoComplete="new-password" // Use non-standard value
                                                />
                                                <div className="whatsapp-info">
                                                    <div className="first-line">
                                                        <span className="italic-text">
                                                            <i>Make sure</i>
                                                        </span>
                                                        <img
                                                            src="assets/images/icons/whatsapp.svg"
                                                            alt="WhatsApp Logo"
                                                            className="whatsapp-logo-inline"
                                                        />
                                                        <span className="italic-text">
                                                            <i>
                                                                WhatsApp is
                                                                enabled on this
                                                                number to
                                                            </i>
                                                        </span>
                                                    </div>
                                                    <div className="second-line">
                                                        <span className="italic-text">
                                                            <i>
                                                                receive Impact
                                                                Reports
                                                            </i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <Field
                                                    name="phone"
                                                    render={({ meta }) => (
                                                        <div className="error">
                                                            {meta.error &&
                                                            meta.touched ? (
                                                                <span>
                                                                    {meta.error}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <Field
                                            component="input"
                                            name="email"
                                            type="text"
                                            className="form-control input-control"
                                            placeholder="Email"
                                            autoComplete="new-password" // Use non-standard value
                                        />
                                        <Field
                                            name="email"
                                            render={({ meta }) => (
                                                <div className="error">
                                                    {meta.error &&
                                                    meta.touched ? (
                                                        <span>
                                                            {meta.error}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </form>
                                </Modal.Body>
                                <Modal.Footer
                                    style={{
                                        border: "none",
                                    }}
                                    className="modal-bg"
                                >
                                    <div
                                        style={{
                                            fontSize: "12px",
                                            width: "90%",
                                            textAlign: "center",
                                        }}
                                    >
                                        By submitting your response, you agree
                                        to our{" "}
                                        <a href="terms.pdf" target="_blank">
                                            Terms & Conditions
                                        </a>
                                    </div>
                                    <br />

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            flex: 1,
                                            fontSize: "13px",
                                        }}
                                    >
                                        <div>
                                            <span>
                                                <b>
                                                    Please verify your details{" "}
                                                </b>{" "}
                                                <br /> Name:{" "}
                                                {form.getState().values.name}
                                            </span>
                                            <br />
                                            <div
                                                style={{
                                                    display: "flex",
                                                }}
                                            >
                                                <span>Phone No: </span>
                                                <span>
                                                    <img
                                                        style={{
                                                            marginTop: "3px",
                                                        }}
                                                        src="assets/images/icons/whatsapp.svg"
                                                        alt="WhatsApp Logo"
                                                        className="whatsapp-logo-inline"
                                                    />
                                                </span>
                                                {form.getState().values.ext} -{" "}
                                                {form.getState().values.phone}
                                            </div>
                                            Email:{" "}
                                            {form.getState().values.email}
                                        </div>
                                        <div className="buttons">
                                            <a
                                                style={{
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginRight: "20px",
                                                }}
                                                href=""
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    form.submit();
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "35px",
                                                    }}
                                                    src="assets/images/icons/submit.svg"
                                                    alt=""
                                                />
                                                <span
                                                    style={{
                                                        fontSize: 15,
                                                        color: "#3E3E3E",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Submit
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </>
                        )}
                    />
                </Modal>
            </div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={isModalOpen}
                    onHide={() => {
                        setIsModalOpen(false);
                    }}
                >
                    <Modal.Header
                        closeButton
                        className="modal-bg"
                        style={{ border: "none", justifyContent: "center" }}
                    >
                        <Modal.Title
                            style={{
                                width: "100%",
                            }}
                        >
                            <div className="row">
                                <div className="col-2">
                                    <img
                                        src="assets/images/icons/task.svg"
                                        className="icon"
                                        alt=""
                                    />
                                </div>
                                <div
                                    className="col-10"
                                    style={{
                                        fontSize: isMobile ? "14px" : "16px",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    {initiative?.title}
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-bg" style={{ border: "none" }}>
                        <div className="wrapper">
                            <div className="box">
                                <div
                                    className="description-modal"
                                    dangerouslySetInnerHTML={{
                                        __html: initiative?.description?.replace(
                                            /\n/g,
                                            "<br/>"
                                        ),
                                    }}
                                ></div>
                                <Form
                                    onSubmit={onSubmit}
                                    validate={validate}
                                    render={({
                                        handleSubmit,
                                        submitting,
                                        pristine,
                                        form,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            {initiative?.questions?.map(
                                                (question) => {
                                                    if (
                                                        question?.type ===
                                                        "number"
                                                    ) {
                                                        return (
                                                            <React.Fragment
                                                                key={
                                                                    question._id
                                                                }
                                                            >
                                                                <Field
                                                                    className="action-input"
                                                                    component="input"
                                                                    name={
                                                                        question?._id
                                                                    }
                                                                    placeholder={
                                                                        question?.placeholder
                                                                    }
                                                                    type="number"
                                                                    min="0"
                                                                    max="99"
                                                                    onFocus={(
                                                                        e: any
                                                                    ) => {
                                                                        e.target.placeholder =
                                                                            "";
                                                                    }}
                                                                    onBlur={(
                                                                        e: any
                                                                    ) => {
                                                                        e.target.placeholder =
                                                                            question?.placeholder;
                                                                    }}
                                                                    onKeyDown={(
                                                                        e: any
                                                                    ) => {
                                                                        if (
                                                                            e.key ===
                                                                                "-" ||
                                                                            e.key ===
                                                                                "+" ||
                                                                            e.key ===
                                                                                "e"
                                                                        ) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                                <Field
                                                                    name={
                                                                        question?._id
                                                                    }
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div
                                                                            className="error"
                                                                            style={{
                                                                                textAlign:
                                                                                    "center",
                                                                            }}
                                                                        >
                                                                            {meta.error &&
                                                                            meta.touched ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    }
                                                }
                                            )}

                                            <div
                                                className="buttons"
                                                style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        form.submit();
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "35px",
                                                        }}
                                                        src="assets/images/icons/submit.svg"
                                                        alt=""
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: "15px",
                                                            color: "#3E3E3E",
                                                            fontWeight: "bold",
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                    >
                                                        Submit
                                                    </span>
                                                </a>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer
                        className="modal-bg"
                        style={{ border: "none" }}
                    ></Modal.Footer>
                </Modal>
            </div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={successModalIsOpen}
                    onHide={() => {
                        setIsSuccessModalIsOpen(false);
                        window.location.reload();
                    }}
                >
                    <Modal.Header
                        closeButton
                        className="modal-bg"
                        style={{ border: "none", justifyContent: "center" }}
                    >
                        <Modal.Title>You have made an impact!</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-bg" style={{ border: "none" }}>
                        <div className="modal-body-container">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: impactText.replace(/\n/g, "<br/>"),
                                }}
                            ></div>
                            <div className="share-invite">
                                <b>
                                    <i>Share your impact &nbsp;</i>
                                </b>
                                <img
                                    onClick={() => {
                                        dispatch(
                                            shareInitiative({
                                                type: "whatsapp",
                                                initiative_id: initiative._id,
                                            })
                                        );
                                        const encodedMessage =
                                            generateImpactShareMessage(
                                                initiative.title,
                                                currentImpact
                                            );

                                        const whatsappURL = `https://api.whatsapp.com/send?text=${encodedMessage}`;
                                        window.open(whatsappURL, "_blank");
                                    }}
                                    style={{
                                        width: "25px",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                    }}
                                    src="/assets/images/icons/whatsapp-share.svg"
                                    alt="WhatsApp Logo"
                                    className="whatsapp-logo-inline"
                                />
                                <img
                                    onClick={(e: any) => {
                                        dispatch(
                                            shareInitiative({
                                                type: "instagram",
                                                initiative_id: initiative._id,
                                            })
                                        );
                                        const message =
                                            generateImpactCopyMessage(
                                                initiative.title,
                                                currentImpact
                                            );
                                        copyToClipboard(e, message);
                                        const instagramDMUrl =
                                            "https://www.instagram.com/direct/inbox/";
                                        window.open(instagramDMUrl, "_blank");
                                    }}
                                    style={{
                                        width: "25px",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                    }}
                                    src="/assets/images/icons/instagram-share.svg"
                                    alt="Link logo"
                                    className="whatsapp-logo-inline"
                                />
                                <img
                                    onClick={(e: any) => {
                                        const isMobile =
                                            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                                                navigator.userAgent
                                            );
                                        const message =
                                            generateImpactCopyMessage(
                                                initiative.title,
                                                currentImpact
                                            );

                                        if (isMobile) {
                                            // Open the sharing intent on mobile devices
                                            const shareData = {
                                                title: initiative.title,
                                                text: message,
                                                url: window.location.href,
                                            };

                                            if (navigator.share) {
                                                navigator
                                                    .share(shareData)
                                                    .catch((err) =>
                                                        console.log(
                                                            "Error sharing",
                                                            err
                                                        )
                                                    );
                                            } else {
                                                console.log(
                                                    "Sharing not supported on this browser"
                                                );
                                            }
                                        } else {
                                            // Copy the link to clipboard on desktop
                                            dispatch(
                                                shareInitiative({
                                                    type: "copylink",
                                                    initiative_id:
                                                        initiative._id,
                                                })
                                            );
                                            copyToClipboard(e, message);
                                        }
                                    }}
                                    style={{
                                        width: "25px",
                                        cursor: "pointer",
                                        marginLeft: "20px",
                                    }}
                                    src="/assets/images/icons/copy-link-share.svg"
                                    alt="Link logo"
                                    className="whatsapp-logo-inline"
                                />
                                <Overlay
                                    target={target}
                                    show={showTooltip}
                                    placement="top"
                                >
                                    {(props) => (
                                        <Tooltip
                                            id="copy-link-tooltip"
                                            {...props}
                                        >
                                            Link copied!
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </div>
                            <div>
                                <b style={{ fontStyle: "italic" }}>
                                    <i>
                                        You will receive your impact report on
                                        the WhatsApp number you submitted within
                                        24-72 hours from <br />
                                        +91 89285 45695. Please save this number
                                        as ReThink.
                                    </i>
                                </b>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer
                        className="modal-bg"
                        style={{ border: "none" }}
                    ></Modal.Footer>
                </Modal>
            </div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={suggestModalOpen}
                    onHide={() => {
                        setIsSuggestModalOpen(false);
                    }}
                >
                    <Modal.Header
                        closeButton
                        className="modal-bg"
                        style={{ border: "none" }}
                    >
                        <Modal.Title>Suggest a Micro-action</Modal.Title>
                    </Modal.Header>
                    {!localStorage.getItem("token") && (
                        <div
                            className="modal-bg"
                            style={{
                                fontSize: "12px",
                                paddingLeft: "17px",
                                paddingRight: "17px",
                                marginTop: "-12px",
                            }}
                        >
                            We’ll share cool sustainability tips, your impact
                            reports, stories, the next micro-actions, and
                            incentives!
                        </div>
                    )}
                    <Form
                        onSubmit={onSuggestionSubmit}
                        validate={suggestionValidate}
                        initialValues={{
                            ext: callingCode || "+91",
                        }}
                        render={({
                            handleSubmit,
                            submitting,
                            pristine,
                            form,
                        }) => (
                            <>
                                <Modal.Body
                                    className="modal-bg"
                                    style={{ border: "none" }}
                                >
                                    <form
                                        onSubmit={handleSubmit}
                                        autoComplete="new-password"
                                    >
                                        {!localStorage.getItem("token") && (
                                            <>
                                                <Field
                                                    component="input"
                                                    name="name"
                                                    type="text"
                                                    className="form-control input-control"
                                                    placeholder="First Name & Last Name"
                                                    autoComplete="new-password" // Use non-standard value
                                                />
                                                <Field
                                                    name="name"
                                                    render={({ meta }) => (
                                                        <div className="error">
                                                            {meta.error &&
                                                            meta.touched ? (
                                                                <span>
                                                                    {meta.error}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />
                                                <br />
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <Field
                                                            name="ext"
                                                            component="select"
                                                            className="form-control input-control input-control-arrow"
                                                        >
                                                            {countryCodes.map(
                                                                (code: any) => (
                                                                    <option
                                                                        key={
                                                                            code.code
                                                                        }
                                                                        value={
                                                                            code.dial_code
                                                                        }
                                                                    >
                                                                        {
                                                                            code.dial_code
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="ext"
                                                            render={({
                                                                meta,
                                                            }) => (
                                                                <div className="error">
                                                                    {meta.error &&
                                                                    meta.touched ? (
                                                                        <span>
                                                                            {
                                                                                meta.error
                                                                            }
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <Field
                                                            component="input"
                                                            name="phone"
                                                            type="number"
                                                            onKeyDown={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    e.key ===
                                                                    "e"
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            className="form-control input-control"
                                                            placeholder="Mobile Number"
                                                            autoComplete="new-password" // Use non-standard value
                                                        />
                                                        <div className="whatsapp-info">
                                                            <div className="first-line">
                                                                <span className="italic-text">
                                                                    <i>
                                                                        Make
                                                                        sure
                                                                    </i>
                                                                </span>
                                                                <img
                                                                    src="assets/images/icons/whatsapp.svg"
                                                                    alt="WhatsApp Logo"
                                                                    className="whatsapp-logo-inline"
                                                                />
                                                                <span className="italic-text">
                                                                    <i>
                                                                        WhatsApp
                                                                        is
                                                                        enabled
                                                                        on this
                                                                        number
                                                                        to
                                                                    </i>
                                                                </span>
                                                            </div>
                                                            <div className="second-line">
                                                                <span className="italic-text">
                                                                    <i>
                                                                        receive
                                                                        Impact
                                                                        Reports
                                                                    </i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <Field
                                                            name="phone"
                                                            render={({
                                                                meta,
                                                            }) => (
                                                                <div className="error">
                                                                    {meta.error &&
                                                                    meta.touched ? (
                                                                        <span>
                                                                            {
                                                                                meta.error
                                                                            }
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                                <Field
                                                    component="input"
                                                    name="email"
                                                    type="text"
                                                    className="form-control input-control"
                                                    placeholder="Email"
                                                    autoComplete="new-password" // Use non-standard value
                                                />
                                                <Field
                                                    name="email"
                                                    render={({ meta }) => (
                                                        <div className="error">
                                                            {meta.error &&
                                                            meta.touched ? (
                                                                <span>
                                                                    {meta.error}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />
                                            </>
                                        )}

                                        <br />
                                        <Field
                                            component="input"
                                            name="suggestion"
                                            type="text"
                                            className="form-control input-control"
                                            placeholder="Suggestion"
                                            autoComplete="new-password" // Use non-standard value
                                        />
                                        <Field
                                            name="suggestion"
                                            render={({ meta }) => (
                                                <div className="error">
                                                    {meta.error &&
                                                    meta.touched ? (
                                                        <span>
                                                            {meta.error}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </form>
                                </Modal.Body>
                                <Modal.Footer
                                    style={{
                                        border: "none",
                                    }}
                                    className="modal-bg"
                                >
                                    <div
                                        style={{
                                            fontSize: "12px",
                                            width: "90%",
                                            textAlign: "center",
                                        }}
                                    >
                                        By submitting your response, you agree
                                        to our{" "}
                                        <a href="terms.pdf" target="_blank">
                                            Terms & Conditions
                                        </a>
                                    </div>
                                    <br />
                                    {!localStorage.getItem("token") && (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                flex: 1,
                                                fontSize: "13px",
                                            }}
                                        >
                                            <div>
                                                <span>
                                                    <b>
                                                        Please verify your
                                                        details{" "}
                                                    </b>{" "}
                                                    <br /> Name:{" "}
                                                    {
                                                        form.getState().values
                                                            .name
                                                    }
                                                </span>
                                                <br />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <span>Phone No: </span>
                                                    <span>
                                                        <img
                                                            style={{
                                                                marginTop:
                                                                    "3px",
                                                            }}
                                                            src="assets/images/icons/whatsapp.svg"
                                                            alt="WhatsApp Logo"
                                                            className="whatsapp-logo-inline"
                                                        />
                                                    </span>
                                                    {form.getState().values.ext}{" "}
                                                    -{" "}
                                                    {
                                                        form.getState().values
                                                            .phone
                                                    }
                                                </div>
                                                Email:{" "}
                                                {form.getState().values.email}
                                            </div>
                                            <div className="buttons">
                                                <a
                                                    style={{
                                                        alignItems: "center",
                                                        textDecoration: "none",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        form.submit();
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "35px",
                                                        }}
                                                        src="assets/images/icons/submit.svg"
                                                        alt=""
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: 15,
                                                            color: "#3E3E3E",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Submit
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {localStorage.getItem("token") && (
                                        <div
                                            className="buttons"
                                            style={{
                                                width: "100%",
                                                marginRight: "0",
                                            }}
                                        >
                                            <a
                                                style={{
                                                    alignItems: "center",
                                                    textDecoration: "none",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginRight: "20px",
                                                }}
                                                href=""
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    form.submit();
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "35px",
                                                    }}
                                                    src="assets/images/icons/submit.svg"
                                                    alt=""
                                                />
                                                <span
                                                    style={{
                                                        fontSize: 15,
                                                        color: "#3E3E3E",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Submit
                                                </span>
                                            </a>
                                        </div>
                                    )}
                                </Modal.Footer>
                            </>
                        )}
                    />
                </Modal>
            </div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={thankYouModalOpen}
                    onHide={() => {
                        setThankYouModalOpen(false);
                    }}
                >
                    <Modal.Header
                        closeButton
                        className="modal-bg"
                        style={{ border: "none", justifyContent: "center" }}
                    >
                        <Modal.Title
                            style={{
                                width: "100%",
                            }}
                        >
                            Thank You!
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="modal-bg" style={{ border: "none" }}>
                        <div className="wrapper">
                            Thank you for submitting your suggestion. You are
                            awesome!
                        </div>
                    </Modal.Body>
                    <Modal.Footer
                        className="modal-bg"
                        style={{ border: "none" }}
                    ></Modal.Footer>
                </Modal>
            </div>
            {isMobile ? (
                <div className="filter-wrapper">
                    <span
                        style={{
                            padding: "20px",
                            fontSize: "16px",
                            fontWeight: "bold",
                        }}
                    >
                        Filter Micro-actions:{" "}
                    </span>
                    <MultiSelect
                        className="filter-select"
                        options={filterOptions}
                        value={filterOptions.filter((option) =>
                            activeFilters.includes(option.value)
                        )}
                        onChange={handleFilterChange}
                        disableSearch
                        labelledBy="Select filters"
                        hasSelectAll={false}
                        ItemRenderer={({
                            checked,
                            option,
                            onClick,
                        }: {
                            checked: boolean;
                            option: any;
                            onClick: () => void;
                        }) => (
                            <div
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent default action
                                    onClick(); // Explicitly trigger the onClick event
                                }}
                                style={{
                                    backgroundColor: option.color,
                                    color: option.textColor,
                                    padding: "10px",
                                    margin: 0,
                                    cursor: "pointer",
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={checked}
                                    readOnly
                                    style={{ marginRight: 8 }}
                                />
                                {option.label}
                            </div>
                        )}
                        overrideStrings={{
                            selectSomeItems: "Select filters",
                        }}
                    />
                </div>
            ) : (
                <div className="filter-wrapper">
                    <span
                        style={{
                            padding: "20px",
                            fontSize: "16px",
                            fontWeight: "bold",
                        }}
                    >
                        Filter Micro-actions:{" "}
                    </span>

                    <ul
                        style={{
                            listStyle: "none",
                            display: "flex",
                            margin: 0,
                            padding: 0,
                            flexWrap: "wrap",
                        }}
                    >
                        {filters.map((filter: Filters, index: number) => (
                            <li key={index} style={{ margin: "10px 5px" }}>
                                <button
                                    style={{
                                        backgroundColor: filter.color,
                                        color: filter.textColor,
                                        border: "none",
                                        padding: "10px 20px",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        opacity: activeFilters.includes(
                                            filter.type
                                        )
                                            ? 1
                                            : 0.6,
                                    }}
                                    onClick={() => toggleFilter(filter.type)}
                                >
                                    {filter.label}
                                </button>
                            </li>
                        ))}
                    </ul>

                    <button
                        style={{
                            backgroundColor: "orange",
                            color: "#000000",
                            border: "none",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            right: "10px",
                            alignSelf: "flex-end",
                            position: "absolute",
                            marginBottom: "10px",
                            fontSize: "16px",
                        }}
                        onClick={() => {
                            setIsSuggestModalOpen(true);
                        }}
                    >
                        Suggest a Micro-action
                    </button>
                </div>
            )}

            <div className="wrapper-micro-actions">
                <div className="container">
                    <ul className="micro-actions-ul">
                        {filteredInitiatives.map(
                            (initiative: Initiative, index: number) => {
                                return (
                                    <li
                                        className="micro-actions-li"
                                        key={index}
                                    >
                                        <a
                                            style={{
                                                backgroundColor: "#9E91CA",
                                                borderLeft:
                                                    "7px solid " +
                                                    initiative.specificFilter[0]
                                                        .color,
                                                borderRight:
                                                    "7px solid " +
                                                    initiative.genericFilter
                                                        .color,
                                            }}
                                            className="micro-actions-a"
                                            onClick={(e: any) => {
                                                e.preventDefault();
                                                setInitiative(initiative);
                                                setIsModalOpen(true);
                                            }}
                                        >
                                            {initiative.shortTitle}
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    bottom: 5,
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {initiative?.duration ===
                                                    "month" && (
                                                    <>
                                                        {parseInt(
                                                            (
                                                                (initiative?.totalImpact *
                                                                    12) /
                                                                1000
                                                            ).toString()
                                                        )}{" "}
                                                    </>
                                                )}
                                                {initiative?.duration ===
                                                    "year" && (
                                                    <>
                                                        {parseInt(
                                                            (
                                                                initiative?.totalImpact /
                                                                1000
                                                            ).toString()
                                                        )}{" "}
                                                    </>
                                                )}{" "}
                                                Kgs of CO2e* saved
                                            </div>
                                        </a>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-evenly",
                                                backgroundColor: "#F5B4CD",
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                                borderBottomLeftRadius: "10px",
                                                borderBottomRightRadius: "10px",
                                            }}
                                        >
                                            <a
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    const currentURL =
                                                        window.location.href;
                                                    const encodedMessage =
                                                        generateShareMessage(
                                                            currentURL
                                                        );
                                                    const whatsappURL = `https://api.whatsapp.com/send?text=${encodedMessage}`;
                                                    window.open(
                                                        whatsappURL,
                                                        "_blank"
                                                    );
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "20px",
                                                        height: "auto",
                                                    }}
                                                    src="assets/images/icons/whatsapp-share.svg"
                                                />
                                            </a>
                                            <a
                                                style={{ cursor: "pointer" }}
                                                onClick={(e: any) => {
                                                    const currentURL =
                                                        window.location.href;
                                                    const message =
                                                        generateCopyMessage(
                                                            currentURL
                                                        );
                                                    copyToClipboard(e, message);
                                                    const instagramDMUrl =
                                                        "https://www.instagram.com/direct/inbox/";
                                                    window.open(
                                                        instagramDMUrl,
                                                        "_blank"
                                                    );
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "20px",
                                                        height: "auto",
                                                    }}
                                                    src="assets/images/icons/instagram-share.svg"
                                                />
                                            </a>
                                            <a
                                                style={{ cursor: "pointer" }}
                                                onClick={(e: any) => {
                                                    const isMobile =
                                                        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                                                            navigator.userAgent
                                                        );
                                                    const message =
                                                        generateImpactCopyMessage(
                                                            initiative.title,
                                                            currentImpact
                                                        );

                                                    if (isMobile) {
                                                        // Open the sharing intent on mobile devices
                                                        const shareData = {
                                                            title: initiative.title,
                                                            text: message,
                                                            url: window.location
                                                                .href,
                                                        };

                                                        if (navigator.share) {
                                                            navigator
                                                                .share(
                                                                    shareData
                                                                )
                                                                .catch((err) =>
                                                                    console.log(
                                                                        "Error sharing",
                                                                        err
                                                                    )
                                                                );
                                                        } else {
                                                            console.log(
                                                                "Sharing not supported on this browser"
                                                            );
                                                        }
                                                    } else {
                                                        // Copy the link to clipboard on desktop
                                                        dispatch(
                                                            shareInitiative({
                                                                type: "copylink",
                                                                initiative_id:
                                                                    initiative._id,
                                                            })
                                                        );
                                                        copyToClipboard(
                                                            e,
                                                            message
                                                        );
                                                    }
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "20px",
                                                        height: "auto",
                                                    }}
                                                    src="assets/images/icons/copy-link-share.svg"
                                                />
                                                <Overlay
                                                    target={target}
                                                    show={showTooltip}
                                                    placement="top"
                                                >
                                                    {(props) => (
                                                        <Tooltip
                                                            id="copy-link-tooltip"
                                                            {...props}
                                                        >
                                                            Link copied!
                                                        </Tooltip>
                                                    )}
                                                </Overlay>
                                            </a>
                                        </div>
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </div>
            </div>
            {isMobile && (
                <button
                    style={{
                        width: "100%",
                        backgroundColor: "orange",
                        color: "#000000",
                        border: "none",
                        padding: "10px 20px",
                        cursor: "pointer",
                        alignSelf: "flex-end",
                        fontSize: "16px",
                    }}
                    onClick={() => {
                        setIsSuggestModalOpen(true);
                    }}
                >
                    Suggest a Micro-action
                </button>
            )}
            <div
                className="container"
                style={{
                    textAlign: "end",
                    fontWeight: "bold",
                    fontSize: "18px",
                }}
            >
                More micro-actions coming soon!
            </div>
        </>
    );
};

export default MicroActions;
