import React, { useEffect, useState } from "react";
import {
    useLocation,
    useParams,
    Link as RouterLink,
    useNavigate,
} from "react-router-dom";

const Header: React.FC = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const navigate = useNavigate();

    const location = useLocation();

    const getUsernameFromPath = () => {
        const pathSegments = location.pathname.split("/");
        return pathSegments[1] || ""; // Assuming the username is the first segment after the root '/'
    };

    const username = getUsernameFromPath();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767); // Adjust the width as per your mobile breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (isMobile) {
        return (
            <header>
                <div style={{ padding: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                navigate(username);
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <a className="logo">
                                    <img
                                        style={{ width: "40px" }}
                                        src="assets/images/logo/rethink-logo.svg"
                                        alt="Logo"
                                    />
                                </a>
                                <a className="logo">
                                    <img
                                        style={{ width: "150px" }}
                                        src="assets/images/logo/logo-color.svg"
                                        alt="Logo"
                                    />
                                </a>
                            </div>
                            <span
                                style={{
                                    fontSize: "18px",
                                    fontFamily: "var(--fw-medium)",
                                    textAlign: "center",
                                }}
                            >
                                An initiative of Re
                            </span>
                        </div>

                        <div
                            className="header-text"
                            style={{
                                fontSize: "16px",
                                marginTop: "5px",
                                marginLeft: "unset",
                                textAlign: "center",
                            }}
                        >
                            Make a macro impact to save the planet through
                            micro-actions - sustainability can be simple and
                            free
                        </div>
                    </div>
                </div>

                {/* <div className="container">
                    <a href="#" className="logo">
                        <img src="assets/images/logo/header.png" alt="Logo" />
                    </a>
                </div> */}
                {/* <nav className="navbar navbar-expand-lg navbar-light">
                    <div
                        className="container-fluid"
                        style={{ justifyContent: "unset" }}
                    >
                        <a className="navbar-brand">
                            <img
                                style={{ width: "200px" }}
                                src="assets/images/logo/re-logo.svg"
                                alt="Logo"
                            />
                            <img
                                style={{ width: "200px" }}
                                src="assets/images/logo/logo-color.svg"
                                alt="Logo"
                            />
                            <div className="logo-text">
                                <span>An initiative of Re</span>
                                <img
                                    src="assets/images/logo/re-logo.svg"
                                    alt="Logo"
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        margin: "0 5px",
                                    }}
                                />
                                <span>Re</span>
                            </div>
                        </a>
                        <div className="vertical-seperator"></div>
                        <div className="header-text">
                            Make a macro impact to save the planet through
                            micro-actions - <br /> sustainability can be simple and
                            free
                        </div>
                    </div>
                </nav> */}
            </header>
        );
    } else {
        return (
            <header>
                <div style={{ padding: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                navigate(username);
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <a className="logo">
                                    <img
                                        style={{ width: "60px" }}
                                        src="assets/images/logo/rethink-logo.svg"
                                        alt="Logo"
                                    />
                                </a>
                                <a className="logo">
                                    <img
                                        style={{ width: "200px" }}
                                        src="assets/images/logo/logo-color.svg"
                                        alt="Logo"
                                    />
                                </a>
                            </div>
                            <span
                                style={{
                                    fontSize: "18px",
                                    fontFamily: "var(--fw-medium)",
                                    marginLeft: "60px",
                                }}
                            >
                                An initiative of Re
                            </span>
                        </div>

                        <div className="vertical-seperator"></div>
                        <div className="header-text">
                            Make a macro impact to save the planet through
                            micro-actions - <br /> sustainability can be simple
                            and free
                        </div>
                    </div>
                </div>

                {/* <div className="container">
                    <a href="#" className="logo">
                        <img src="assets/images/logo/header.png" alt="Logo" />
                    </a>
                </div> */}
                {/* <nav className="navbar navbar-expand-lg navbar-light">
                    <div
                        className="container-fluid"
                        style={{ justifyContent: "unset" }}
                    >
                        <a className="navbar-brand">
                            <img
                                style={{ width: "200px" }}
                                src="assets/images/logo/re-logo.svg"
                                alt="Logo"
                            />
                            <img
                                style={{ width: "200px" }}
                                src="assets/images/logo/logo-color.svg"
                                alt="Logo"
                            />
                            <div className="logo-text">
                                <span>An initiative of Re</span>
                                <img
                                    src="assets/images/logo/re-logo.svg"
                                    alt="Logo"
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        margin: "0 5px",
                                    }}
                                />
                                <span>Re</span>
                            </div>
                        </a>
                        <div className="vertical-seperator"></div>
                        <div className="header-text">
                            Make a macro impact to save the planet through
                            micro-actions - <br /> sustainability can be simple and
                            free
                        </div>
                    </div>
                </nav> */}
            </header>
        );
    }
};

export default Header;
