import { createSlice } from '@reduxjs/toolkit';
import Initiative from '../../interfaces/initiative';

interface CommonState {
  faqOpen: boolean|null
}

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    faqOpen: null
  } as CommonState,
  reducers: {
    setFaqOpen:(state,action) =>{
        state.faqOpen = action.payload;
    },
  }
});

export const { setFaqOpen  } = commonSlice.actions;
export default commonSlice.reducer;