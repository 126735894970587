import React, { Dispatch, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InitiativeForm from "../screens/InitiativeForm";
import MicroActions from "../screens/MicroActions";

const AppRouter: React.FC = () => {
    const NotFound: React.FC = () => (
        <div>
            <h2>404 - Not Found</h2>
            {/* You can add more content or a link to the home page */}
        </div>
    );

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/rethink" />} />
            <Route path="/:username" element={<InitiativeForm />} />
            <Route path="/:username/micro-actions" element={<MicroActions />} />
            <Route path="/404" element={<NotFound />} />
        </Routes>
    );
};

export default AppRouter;
