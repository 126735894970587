import { combineReducers } from 'redux';
import initiativeReducer from './initiative';
import userReducer from './user';
import commonReducer from './common';

const rootReducer = combineReducers({
    initiative: initiativeReducer,
    user: userReducer,
    common: commonReducer
});

export default rootReducer;