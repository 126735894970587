import React, { useEffect, useState } from "react";
import { setFaqOpen } from "../redux/reducers/common";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { Link as ScrollLink } from "react-scroll";
import { useParams, Link as RouterLink, useLocation } from "react-router-dom";

const Footer: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const location = useLocation();

    const getUsernameFromPath = () => {
        const pathSegments = location.pathname.split("/");
        return pathSegments[1] || ""; // Assuming the username is the first segment after the root '/'
    };

    const username = getUsernameFromPath();

    const faqOpen: boolean = useSelector((state: any) => state.common.faqOpen);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767); // Adjust the width as per your mobile breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (isMobile) {
        return (
            <footer>
                <div className="container">
                    <div className="top">
                        <div className="row align-items-center">
                            <div className="col-10">
                                <div className="row">
                                    <div className="col-8 col-md-3">
                                        <RouterLink
                                            to={`/${username}#ongoing`}
                                            className="mobile-footer-link"
                                        >
                                            Take a Micro-action
                                        </RouterLink>
                                    </div>
                                    <div
                                        className="col-4 col-md-3"
                                        style={{ padding: "0" }}
                                    >
                                        <RouterLink
                                            to={`/${username}#story`}
                                            className="mobile-footer-link"
                                        >
                                            The Story
                                        </RouterLink>
                                    </div>
                                    <div className="col-8 col-md-3">
                                        <RouterLink
                                            to={`/${username}#upcoming_concluded`}
                                            className="mobile-footer-link"
                                        >
                                            Explore Micro-actions
                                        </RouterLink>
                                    </div>
                                    <div
                                        className="col-4 col-md-3"
                                        style={{ padding: "0" }}
                                    >
                                        <RouterLink
                                            to={`/${username}#impact`}
                                            className="mobile-footer-link"
                                        >
                                            Impact
                                        </RouterLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                                <img
                                    className="mobile-footer-logo"
                                    src="assets/images/logo/logo-black.svg"
                                    alt="Logo"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="row align-items-center justify-content-between">
                            <div className="col">
                                <div className="socialMedia">
                                    <h2>Follow us</h2>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/company/re-ecoverse"
                                                target="_blank"
                                                className="icon"
                                            >
                                                <img
                                                    src="assets/images/icons/linkedin.svg"
                                                    alt="LinkedIn"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/rethink_sustainability/"
                                                target="_blank"
                                                className="icon"
                                            >
                                                <img
                                                    src="assets/images/icons/instagram.svg"
                                                    alt="Instagram"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://x.com/ReThinkEcoverse"
                                                target="_blank"
                                                className="icon"
                                            >
                                                <img
                                                    src="assets/images/icons/twitter.svg"
                                                    alt="Twitter"
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col">
                                <div
                                    className="socialMedia"
                                    style={{ float: "right" }}
                                >
                                    <ul>
                                        <li>
                                            <a href="mailto:rethink@getre.org">
                                                <div className="icon">
                                                    <img
                                                        src="assets/images/icons/sms-tracking.svg"
                                                        alt="Email"
                                                    />
                                                </div>
                                                rethink@getre.org
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <span
                            style={{
                                fontSize: "10px",
                            }}
                        >
                            * calculated based on certain assumptions
                        </span>
                    </div>
                </div>
            </footer>
        );
    } else {
        return (
            <footer>
                <div className="container">
                    <div className="top">
                        <div className="row align-items-center">
                            <div className="col-10">
                                <ul className="footer-links">
                                    <li>
                                        <RouterLink to={`/${username}#ongoing`}>
                                            Take a Micro-action
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to={`/${username}#story`}>
                                            The Story
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink
                                            to={`/${username}#upcoming_concluded`}
                                        >
                                            Explore Micro-actions
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to={`/${username}#impact`}>
                                            Impact
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-2">
                                <img
                                    src="assets/images/logo/logo-black.svg"
                                    alt="Logo"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="row align-items-center justify-content-between">
                            <div className="col">
                                <div className="socialMedia">
                                    <h2>Follow us</h2>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/company/re-ecoverse"
                                                target="_blank"
                                                className="icon"
                                            >
                                                <img
                                                    src="assets/images/icons/linkedin.svg"
                                                    alt="LinkedIn"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/rethink_sustainability/"
                                                target="_blank"
                                                className="icon"
                                            >
                                                <img
                                                    src="assets/images/icons/instagram.svg"
                                                    alt="Instagram"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://x.com/ReThinkEcoverse"
                                                target="_blank"
                                                className="icon"
                                            >
                                                <img
                                                    src="assets/images/icons/twitter.svg"
                                                    alt="Twitter"
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col">
                                <div
                                    className="socialMedia"
                                    style={{ float: "right" }}
                                >
                                    <ul>
                                        <li>
                                            <a href="mailto:rethink@getre.org">
                                                <div className="icon">
                                                    <img
                                                        src="assets/images/icons/sms-tracking.svg"
                                                        alt="Email"
                                                    />
                                                </div>
                                                rethink@getre.org
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        style={{
                            fontSize: "12px",
                        }}
                    >
                        * calculated based on certain assumptions
                    </span>
                </div>
            </footer>
        );
    }
};

export default Footer;
