import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import Initiative from '../../interfaces/initiative';
import Filters from '../../interfaces/filters';

interface InitiativeState {
  items: Initiative[];
  initiative: Initiative;
  currentInitiative: Initiative;
  locations: number,
  totalImpact: number,
  added: boolean| null;
  impactText: String;
  totalUsers: number;
  error: string | null;
  filters: Filters[];
  currentImpact: number;
}

interface getInitiativeResponse {
  initiatives : Initiative[],
  locations: number,
  totalImpact: number,
  totalUsers: number
}

export const getCurrentInitiative = createAsyncThunk('/getCurrentInitiative', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/initiative',{params: {
        type: "current"
    }});
    return response.data as getInitiativeResponse;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const getAllInitiatives = createAsyncThunk('/getAllInitiatives', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/initiative');
    return response.data as getInitiativeResponse;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addResponse = createAsyncThunk('/addResponse', async (value:any, { rejectWithValue }) => {
  try {
    const response = await api.post('/responses', value);
    return response.data as any;
  } catch (error:any) {
    return rejectWithValue(error.response.data.error);
  }
});

export const getAllFilters = createAsyncThunk('/getAllFilters', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/initiative/filters');
    return response.data as Filters[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

interface shareParams{
  type:string,
  initiative_id: string
}

export const shareInitiative = createAsyncThunk('/shareInitiative', async (values:shareParams, { rejectWithValue }) => {
  try {
    const response = await api.post('/initiative/share', values);
    return response.data as any;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

const initiativeSlice = createSlice({
  name: 'initiative',
  initialState: {
    items: [],
    initiative: {} as Initiative,
    currentInitiative: {} as Initiative,
    added:null,
    error:null,
    locations:0,
    totalImpact: 0,
    impactText: '',
    totalUsers: 0,
    filters: [] as Filters[],
    currentImpact: 0
  } as InitiativeState,
  reducers: {
    setInitiative:(state,action) =>{
        state.initiative = action.payload;
    },
    setInitiativeAdded:(state,action) =>{
      state.added = action.payload;
  }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentInitiative.fulfilled, (state,action) => {
        state.currentInitiative = action.payload.initiatives[0];
        state.locations = action.payload.locations;
        state.totalImpact = action.payload.totalImpact;
        state.totalUsers = action.payload.totalUsers;
      })
      .addCase(addResponse.fulfilled, (state,action) => {
        state.added = true;
        state.impactText = action.payload.impactText;
        state.currentImpact = action.payload.currentImpact;
      })
      .addCase(addResponse.rejected, (state,action) => {
        state.error = action.payload as string;
        alert(state.error);
      }).addCase(getAllInitiatives.fulfilled, (state,action) => {
        state.items = action.payload.initiatives;
        state.locations = action.payload.locations;
        state.totalImpact = action.payload.totalImpact;
        state.totalUsers = action.payload.totalUsers;
      }).addCase(getAllFilters.fulfilled, (state,action) => {
        state.filters = action.payload
      });
  },
});

export const { setInitiative, setInitiativeAdded } = initiativeSlice.actions;
export default initiativeSlice.reducer;